import { MenuEntry } from '@pixelswap/uikit'

// const href = "http://pixel.gotoup.space"
const href = 'https://pixel.pixelfinance.io' // 正式链

const config: MenuEntry[] = [
  {
    label: 'HOME',
    icon: 'Home',
    href: `${href}/home`,
  },
  {
    label: 'TRADE',
    icon: 'Trade',
    items: [
      {
        label: 'EXCHANGE',
        href: '/swap',
      },
      {
        label: 'LIQUIDITY',
        href: '/liquidity',
      },
    ],
  },
  {
    label: 'FARMS',
    icon: 'Farms',
    href: `${href}/farms`,
    // href: '',
  },
  {
    label: 'POOLS',
    icon: 'Pools',
    href: `${href}/pools`,
    // href: '',
  },
  // {
  //   label: 'LOTTERY',
  //   icon: 'Lottery',
  //   // href: `${href}/Lottery`,
  //   href: '',
  // },
  {
    label: 'AIRDROP',
    icon: 'Airdrop',
    // href: `${href}/Airdrop`,
    href: '',
  },
  {
    label: 'PIXEL STARTER',
    icon: 'Starter',
    // href: `${href}/starter`,
    href: '',
  },
  {
    label: 'MORE',
    icon: 'More',
    items: [
      // {
      //   label: 'PRESALE',
      //   // icon: 'Starter',
      //   href: `${href}/presale`,
      // }
    ]
  },
]

export default config
