import { useEffect } from 'react'
import BigNumber from 'bignumber.js'
import useGetPriceData from './useGetPriceData'
import { PIXEL, WBNB } from '../constants'

const useGetDocumentTitlePrice = () => {

  const bnbPriceData = useGetPriceData(WBNB.address)
  const pixelPriceData = useGetPriceData(PIXEL.address)
  const priceUsd = (bnbPriceData && pixelPriceData) ? Number(new BigNumber(bnbPriceData.data.price).times(new BigNumber(pixelPriceData.data.price_BNB)).toFixed(3)) : 0

  const cakePriceUsdString =
    Number.isNaN(priceUsd) || priceUsd === 0
      ? ''
      : ` - $${priceUsd.toLocaleString(undefined, {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      })}`

  useEffect(() => {
    document.title = `PixelFinance`
  }, [cakePriceUsdString])
}
export default useGetDocumentTitlePrice
