import React, { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { Menu as UikitMenu } from '@pixelswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { allLanguages } from 'constants/localisation/languageCodes'
import { LanguageContext } from 'hooks/LanguageContext'
import useTheme from 'hooks/useTheme'
import useGetPriceData from 'hooks/useGetPriceData'
import useGetLocalProfile from 'hooks/useGetLocalProfile'
import useAuth from 'hooks/useAuth'
import config from './config'
import configland from './configland'
import styled from 'styled-components'
import { PIXEL, WBNB } from '../../constants'

const Menu: React.FC = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { selectedLanguage, setSelectedLanguage } = useContext(LanguageContext)
  const { isDark, toggleTheme } = useTheme()
  const bnbPriceData = useGetPriceData(WBNB.address)
  const pixelPriceData = useGetPriceData(PIXEL.address)
  const priceUsd =
    bnbPriceData && pixelPriceData
      ? Number(new BigNumber(bnbPriceData.data.price).times(new BigNumber(pixelPriceData.data.price_BNB)).toFixed(3))
      : undefined

  // const cakePriceUsd = priceData ? Number(priceData.data[CAKE.address].price) : undefined
  const profile = useGetLocalProfile()

  const location = useLocation()

  return (
    <UikitMenu
      links={config}
      account={account as string}
      login={login}
      logout={logout}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={'en' || selectedLanguage?.code}
      langs={allLanguages}
      setLang={setSelectedLanguage}
      cakePriceUsd={priceUsd}
      profile={profile}
      {...props}
    />
  )
}

export default Menu
