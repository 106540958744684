import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { ButtonMenu, ButtonMenuItem } from '@pixelswap/uikit'
import useI18n from 'hooks/useI18n'
import { isMobile } from 'react-device-detect'

const StyledNav = styled.div`
  margin-bottom: 40px;
`

function Nav({ activeIndex = 0 }: { activeIndex?: number }) {
  const TranslateString = useI18n()
  return (
    <StyledNav style={{ maxWidth: '400px', width: '100%' }}>
      <ButtonMenu
        activeIndex={activeIndex}
        scale="sm"
        variant="tabButton"
        style={{ width: '100%', backgroundColor: '#213A6C', borderRadius: '40px' }}
      >
        <ButtonMenuItem
          id="swap-nav-link"
          to="/swap"
          as={Link}
          style={{ fontSize: '22px', padding: '30px', borderRadius: '35px', width: '50%', color: '#fff' }}
        >
          {TranslateString(1142, 'Swap')}
        </ButtonMenuItem>
        <ButtonMenuItem
          id="Liquidity-nav-link"
          to="/Liquidity"
          as={Link}
          style={{ fontSize: '22px', padding: '30px', borderRadius: '35px', width: '50%', color: '#fff' }}
        >
          {TranslateString(262, 'Liquidity')}
        </ButtonMenuItem>
        {/* <ButtonMenuItem style={{padding: '20px', borderRadius: '20px', width: '33%', color: '#fff'}}
          id="pool-nav-link"
          as="a"
          href="https://www.binance.org/en/bridge?utm_source=PIXEL"
          target="_blank"
          rel="noreferrer noopener"
        >
          {TranslateString(262, 'Bridge')}
        </ButtonMenuItem> */}
      </ButtonMenu>
    </StyledNav>
  )
}

export default Nav
