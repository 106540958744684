import { useEffect, useState } from 'react'

type ApiResponseV1 = {
  updated_at: string
  data: {
    [key: string]: {
      name: string
      symbol: string
      price: string
      price_BNB: string
    }
  }
}

type ApiResponse = {
  updated_at: string
  data: {
      name: string
      symbol: string
      price: string
      price_BNB: string
  }
}

const apiV1 = 'https://pancake-info-api-develop-zeta.vercel.app/api/tokens'
const api = 'https://api.pancakeswap.info/api/v2/tokens/'

const useGetPriceData = (TokenAddress) => {

  const [data, setData] = useState<ApiResponse | null>(null)

  useEffect(() => {

    const fetchDataV1 = async (address) => {
      try {
        const response = await fetch(apiV1)
        const res: ApiResponseV1 = await response.json()
        if (res.data[address]) {
          const result: ApiResponse = {
            updated_at: res.updated_at,
            data: res.data[address]
          }
          setData(result)
        }
      } catch (error) {
        console.error('Unable to fetch price data:', error)
      }
    }

    
    const fetchData = async (address) => {
      try {
        const response = await fetch(api + address)
        const res: ApiResponse = await response.json()
        if (!res.updated_at) {
          await fetchDataV1(address)
        } else {
          setData(res)
        }
      } catch (error) {
        console.error('Unable to fetch price data:', error)
      }
    }

    fetchData(TokenAddress)
  }, [setData, TokenAddress])

  return data
}

export default useGetPriceData
